import '../scss/style.scss';
import { animate, inView, stagger, timeline } from "motion"

const sequence = [
    [document.querySelector('.hero__text'), { opacity: 1, transform: 'translate(0, -50%)' }, { duration: 1.5 }, { easing: 'ease-out' }],
    [document.querySelector('.hero__image'), { opacity: 1, transform: 'translateX(0)' }, { duration: 1, at: '-1' }, { easing: 'ease-out' }, ],
];

timeline(sequence, {duration: 1.5});

inView('.examples__list', () => {
    animate('.examples__list li', { opacity: 1, transform: 'translateX(0)' }, { duration: 1, delay: stagger(0.1) },  { easing: 'ease-out' } )
}, { margin: '0px 0px -15% 0px' });

inView('.pricing__table li', (e) => {
    animate(e.target, { opacity: 1, transform: 'translateX(0)' }, { easing: 'ease-out' } )
}, { margin: '0px 0px -15% 0px' });

inView('.video video', (e) => {
    e.target.play();
});

document.addEventListener('click', (e) => {
    if (!e.target.closest('.header__nav a')) return;

    e.preventDefault();
    document.getElementById(e.target.closest('.header__nav a').getAttribute('href').split('#')[1]).scrollIntoView({ behavior: "smooth" });
});

if (document.querySelector('.header__message')) document.querySelector('.header__message').classList.add('is-visible');

document.addEventListener('click', (e) => {
    if (!e.target.closest('.js-dialog-close')) return;

    e.target.closest('.is-visible').classList.remove('is-visible');
});

document.addEventListener('click', (e) => {
    if (!e.target.closest('.js-button-phone')) return;

    if (window.innerWidth > 991) {
        e.preventDefault();

        document.querySelector('.footer__dialog-phone').classList.add('is-visible');
    }
});
